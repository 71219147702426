@import '../../../../scss/theme-bootstrap';

.loyalty-cancel-overlay,
.loyalty-cancellation-confirm-overlay {
  #cboxContent {
    @include breakpoint($medium-up) {
      padding: 30px;
      padding-bottom: 0;
    }
    .loyalty_popover {
      &__title {
        font-family: $body-font-bold;
        font-size: 23px;
        text-align: $ldirection;
      }
      &__content {
        text-align: center;
        &__checkbox {
          &__content {
            @include breakpoint($medium-up) {
              margin-bottom: 10px;
              width: 91%;
            }
            font-size: 13px;
            padding-#{$ldirection}: 5px;
            display: inline-block;
            vertical-align: top;
            width: 82%;
            margin-bottom: 20px;
            padding-top: 9px;
            line-height: 1.2;
          }
        }
      }
      &__header {
        @include h8;
        text-align: $ldirection;
        font-size: 25px;
        margin-bottom: 20px;
        font-family: $bb-sans;
        letter-spacing: normal;
        width: 90%;
        line-height: 1;
        @include breakpoint($medium-up) {
          font-size: 32px;
          margin-bottom: 5px;
          width: 78%;
        }
      }
      &__sub-header {
        @include h8;
        font-size: 13px;
        border-bottom: 0;
        font-family: $body-font;
        letter-spacing: normal;
        margin-top: 15px;
        text-align: $ldirection;
        text-transform: none;
      }
      &__margin {
        @include h7;
        text-align: $ldirection;
        margin-top: 5px;
      }
      &__contact-us-text {
        margin-top: 20px;
        font-size: 10px;
        line-height: 1.5;
        color: $color-warm-grey;
        &__link {
          cursor: pointer;
          color: $color-warm-grey;
          text-decoration: underline;
        }
      }
      &__buttons {
        margin-top: 20px;
        &__link {
          padding: 0.75em 2em;
          display: block;
          border: 1px solid $color-black;
          &.button--disabled {
            border: none;
          }
        }
        &__retain {
          margin-top: 10px;
          border: none;
          color: $color-warm-grey;
          text-decoration: underline;
          &:hover {
            background: none;
            color: $color-warm-grey;
          }
        }
      }
      &__loyalty {
        &_rejoin {
          &-text {
            text-align: center;
            padding-top: 20px;
          }
        }
        &_cancel {
          &-option {
            color: $color-warm-grey;
            input[type='checkbox']:checked + label {
              color: $color-black;
              &:before {
                background-color: $color-black;
              }
            }
          }
        }
      }
    }
    #cboxClose {
      top: 38px;
      #{$rdirection}: 32px;
      font-size: 16px;
      color: $color-black;
    }
  }
}
